import {mdiExclamation, mdiInformationVariant, mdiThumbDownOutline, mdiThumbUpOutline} from '@mdi/js';
import {Icon} from '@mdi/react';
import React from 'react';
import cls from 'Support/cls';

const NotificationImageOrIcon = ({image, type}) => {
  if (image) {
    return <img className="h-8 w-8 rounded-full object-cover mx-1" src={image} alt="Notification Image"/>;
  }

  const standardClasses = 'h-8 w-8 rounded-full mx-1 p-1 flex items-center justify-center flex-shrink-0';

  switch (type) {
    case 'warning':
      return <span className={cls(standardClasses, 'bg-yellow-100')}><Icon path={mdiExclamation} size={0.7}/></span>;
    case 'positive':
      return <span className={cls(standardClasses, 'bg-green-100')}><Icon path={mdiThumbUpOutline} size={0.7}/></span>;
    case 'negative':
      return <span className={cls(standardClasses, 'bg-red-100')}><Icon path={mdiThumbDownOutline} size={0.7}/></span>;
    case 'informative':
      return <span className={cls(standardClasses, 'bg-blue-100')}><Icon path={mdiInformationVariant} size={0.7}/></span>;
    default:
      return <span className={cls(standardClasses, 'bg-gray-100')}><Icon path={mdiExclamation} size={0.7}/></span>;
  }
}

export default NotificationImageOrIcon;
